<style>
    body,
    #showcase_checkout {
        padding-top: 0px;
    }
    .checkout-left-col {
        padding: 20px;
    }
    .checkout-right-col {
        background-color: rgb(78, 98, 196);
        padding: 60px;
        flex-direction: column;
        color: #fff;
    }
    .powered-by {
        padding: 100px 50px 50px 50px;
        text-align: center;
        line-height: 1;
        position: absolute;
        bottom: -164px;
        left: 0;
        right: 0;
    }
    @media(max-width:767px) {
        .checkout-row {
            margin: 0 -20px;
        }
    }
    @media(min-width:768px) {
        #showcase_checkout > div > .container-fluid {
            padding: 0px;
        }
        .checkout-row {
            display: flex;
            height: 100vh;
            flex-direction: row;
            margin: 0px;
            overflow: hidden;
        }
        .checkout-left-col {
            padding: 60px 60px 0 10%;
            flex-direction: column;
            overflow-y: scroll;
        }
        .checkout-left-col > .container-fluid {
            position: relative;
            min-height: calc(100% - 165px);
        }
    }
    @media (min-width:768px) and (max-width:991px) {
        .checkout-left-col {
            padding: 20px 20px 0 20px;
        }
        .checkout-right-col {
            padding: 20px;
        }
    }
</style>

<template>
    <div>
        <div class="container-fluid">
            <div class="row checkout-row">
                <div class="col-xs-12 col-sm-7 checkout-left-col">
                    <div class="container-fluid">
                        <div>
                            <h3 class="margin-bottom-20">showcase.company.display_name</h3>
                            <ol class="breadcrumb checkout-progress">
                                <li :class="{'active': step===1}">{{$t('views.showcase_checkout.choose_offer')}}</li>
                                <li :class="{'active': step===2}">{{$t('views.showcase_checkout.client_information')}}</li>
                                <li :class="{'active': step===3}">{{$t('views.showcase_checkout.payment_information')}}</li>
                                <!--<li :class="{'active': step===4}">{{$t('views.showcase_checkout.complete_order')}}</li>-->
                            </ol>
                            <hr>
                            <div v-if="step===1">
                                <h1 class="h3 margin-bottom-20">{{$t('views.showcase_checkout.choose_offer')}}</h1>
                                <div class="panel panel-default margin-bottom-40" style="border-color:#000;line-height:1;">
                                    <div class="panel-body">
                                        <div class="row">
                                            <div class="col-xs-1">
                                                <div class="radio margin-0 padding-0">
                                                    <label>
                                                        <input type="radio" name="optionsRadios" id="optionsRadios1" value="option1" checked>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xs-11">
                                                <div class="margin-bottom-5"><b>{{$t('views.showcase_checkout.choose_offer_body_title')}}</b></div>
                                                <div class="row">
                                                    <div class="col-xs-9">
                                                        views.showcase_checkout.choose_offer_body_desc
                                                    </div>
                                                    <div class="col-xs-3 text-right">
                                                        {{getCurrencySymbolFromString(defaultCurrency)}}{{price}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        {{$t('common.cancel')}}
                                    </div>
                                    <div class="col-xs-6 text-right">
                                        <button class="btn btn-primary btn-lg" @click.prevent.stop="step=2">{{$t('common.continue')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="step===2">
                                <h1 class="h3 margin-bottom-20">{{$t('views.showcase_checkout.client_information')}}</h1>
                                <div class="panel panel-default margin-bottom-40">
                                    <div class="panel-body">
                                        <div class="form-horizontal">
                                            <div class="form-group" :class="{'has-error': error.first_name}">
                                                <label for="first_name_client_modal" class="col-xs-12 col-md-4 control-label">{{ $t('common.first_name') }}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <input class="form-control"
                                                           :disabled="checkoutBooking"
                                                           type="text"
                                                           placeholder="es: Mario"
                                                           name="first_name_client_modal"
                                                           id="first_name_client_modal"
                                                           v-model="userConsumer.first_name">
                                                    <span v-if="error.first_name" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group" :class="{'has-error': error.last_name}">
                                                <label for="last_name_client_modal" class="col-xs-12 col-md-4 control-label">{{ $t('common.last_name') }}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <input class="form-control"
                                                           :disabled="checkoutBooking"
                                                           type="text"
                                                           placeholder="es: Rossi"
                                                           id="last_name_client_modal"
                                                           name="last_name_client_modal"
                                                           v-model="userConsumer.last_name">
                                                    <span v-if="error.last_name" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group" :class="{'has-error': error.phone_number}">
                                                <label for="phone_name_client_modal" class="col-xs-12 col-md-4 control-label">{{ $t('common.phone') }}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <VuePhoneNumberInput
                                                            :disabled="checkoutBooking"
                                                            @update="updatePhoneNumber"
                                                            no-validator-state
                                                            :default-country-code="country_phone"
                                                            v-model="userConsumer.phone_number"
                                                            :translations="translations"
                                                            id="phone_name_client_modal"></VuePhoneNumberInput>
                                                    <span v-if="error.phone_number" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group" :class="{'has-error': emailAlreadyRegisteredError}">
                                                <label for="email_name_client_modal" class="col-xs-12 col-md-4 control-label">{{ $t('common.email') }}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <input class="form-control"
                                                           :disabled="checkoutBooking"
                                                           type="email"
                                                           placeholder="es: esempio@gmail.com"
                                                           id="email_name_client_modal"
                                                           name="email_name_client_modal"
                                                           v-model="userConsumer.email">
                                                    <span v-if="emailAlreadyRegisteredError" class="help-block">{{$t('views.showcase_checkout.error_email')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group" :class="{'has-error': error.country}" v-show="!checkoutBooking" >
                                                <label for="country_client_modal" class="col-xs-12 col-md-4 control-label">{{$t('common.country')}}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <input class="form-control"
                                                           :disabled="checkoutBooking"
                                                           type="country"
                                                           id="country_client_modal"
                                                           name="country_client_modal"
                                                           v-model="userConsumer.country">
                                                    <span v-if="error.country" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="country_client_modal" class="col-xs-12 col-md-4 control-label">{{$t('common.country')}}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <country-select :usei18n="false" :autocomplete="true" class="form-control"
                                                                    :disabled="checkoutBooking" v-model="userConsumer.country" :country="userConsumer.country" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <a href="" @click.prevent.stop="step=1">{{ $t('common.back') }}</a>
                                    </div>
                                    <div class="col-xs-6 text-right">
                                        <button class="btn btn-primary btn-lg" @click.prevent.stop="goToPaymentStep">{{$t('common.continue')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="step===3">
                                <h1 class="h3 margin-bottom-20">{{$t('views.showcase_checkout.payment_information')}}</h1>
                                <div class="panel panel-default margin-bottom-40">
                                    <div class="panel-body">
                                        <div class="row margin-bottom-20">
                                            <div class="form-group">
                                                <div class="col-xs-12 col-md-12">
<!--                                                    <PriceTable :price="this.showcase.price" :currency="this.showcase.price_currency"></PriceTable>-->
                                                </div>
                                            </div>
                                        </div>

                                        <StripeElements  ref="elementsRef"
                                                         id="client_credit_card"
                                                         :pk="STRIPE_PUBLIC_KEY"
                                                         :locale="$i18n.locale"
                                                         @token="tokenCreated"></StripeElements>
                                        <div>
                                            <div class="form-group margin-top-20">
<!--                                                <ConversionNote :price="showcase.price" :currency="showcase.price_currency"></ConversionNote>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <a href="" @click.prevent.stop="step=2">{{ $t('common.back') }}</a>
                                    </div>
                                    <div class="col-xs-6 text-right">
                                        <button class="btn btn-primary btn-lg" @click.stop.prevent="confirmPurchase">{{ $t('common.confirm') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="step===4">
                                <h1 class="h3 margin-bottom-20">{{$t('views.showcase_checkout.complete_order')}}</h1>
                                <div class="panel panel-default margin-bottom-40">
                                    <div class="panel-body">
                                        <p>
                                            {{$t('views.showcase_checkout.complete_order_line_1')}}
                                        </p>
                                        <p>
                                            {{$t('views.showcase_checkout.complete_order_line_2')}} <a :href="showcaseLink">{{$t('views.showcase_checkout.click_here')}}</a>
                                        </p>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="powered-by">
                            <span class="text-muted">Powered by Respira Yoga</span>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-5 checkout-right-col">
                    <div class="container-fluid">
                        <h3 style="color:#fff;">showcase.title</h3>
                        <p>showcase.description</p>
                        <small><i>By showcase.company.display_name</i></small>
                    </div>
                </div>
            </div>
        </div>
        <SpinnerLoader v-show="loading"></SpinnerLoader>
    </div>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import SpinnerLoader from "../components/common/SpinnerLoader";
    import {purchaseShowcase} from "@/api";
    import {Utils} from "@/common/utils";
    import {STRIPE_PUBLIC_KEY} from "@/common/constants";
    import { StripeElements } from 'vue-stripe-checkout';

    export default {
        title: "Ticket Booking",
        name: "BookingOnline",
        components: { SpinnerLoader, VuePhoneNumberInput, StripeElements},
        data: () => {
            return {
                userConsumer: {
                },
                errorMessage: "",
                loading: false,
                step: 1,
                newClientPhoneNumberInternational: "",
                translations: Utils.getTranslationsPhoneField(),
                price_credit_card: "0.00",
                price_currency_credit_card: "",
                STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY,
                error: {
                    first_name: false,
                    last_name: false,
                    email: false,
                    country: false,
                    phone: false
                },
                price: 0.00,
                defaultCurrency: Utils.getDefaultCurrency(),
                checkoutBooking: false
            }
        },
        mounted() {
            this.errorMessage = "";

            //TODO check if detect timezone, country and currency
            this.userConsumer.country = Utils.getDefaultCountry();
            this.userConsumer.timezone = Utils.getDefaultTimezone();
            this.userConsumer.default_currency = Utils.getDefaultCurrency();
        },
        computed: {
            country_phone() {
                return Utils.getDefaultCountry();
            }
        },

        methods: {
            confirmPurchase() {
                this.$refs.elementsRef.submit();
            },
            async tokenCreated (token) {
                this.loading = true;
                let stripe = window.Stripe(STRIPE_PUBLIC_KEY);

                this.token = token;
                const result = await stripe.confirmCardPayment(this.client_secret, {
                    payment_method: {
                        card: {
                            token: token.id
                        },
                    }
                });


                if (result.error) {
                    if (result.error.type === "card_error") {
                        this.errorMessage = result.error.message
                    }
                } else {

                    if (result.paymentIntent.status === "succeeded") {
                        this.successBookingReservation()
                    }

                }

                this.loading = false;
            },
            successBookingReservation() {
                this.step=4;
            },

            validateClientForm() {
                //TODO check nationality
                this.error.first_name = false;
                this.error.last_name = false;
                this.error.email = false;
                this.error.phone_number = false;


                if (!this.userConsumer.first_name) {
                    this.error.first_name = true;
                }

                if (!this.userConsumer.last_name) {
                    this.error.last_name = true;
                }

                if (!this.userConsumer.email) {
                    this.error.email = true;
                }

                if (!this.userConsumer.country) {
                    this.error.country = true;
                }

                if (!this.userConsumer.phone_number) {
                    this.error.phone_number = true;
                }

                return !(this.error.first_name ||
                    this.error.last_name ||
                    this.error.email ||
                    this.error.country ||
                    this.error.phone_number)
            },
            async goToPaymentStep() {
                if (this.validateClientForm()) {
                    this.step = 3;
                    if (!this.client_secret) {
                        this.loading = true;
                        try {
                            let response = await purchaseShowcase(this.$route.params.showcase_fingerprint);

                            this.loading = false;
                            this.client_secret = response.payment_client_secret;
                            this.price_credit_card = response.price_amount;
                            this.price_currency_credit_card = response.price_currency;
                        } catch (e) {
                            this.loading = false;
                            console.log(e)
                        }
                    }

                }
                this.errorMessage = "";

            },
            updatePhoneNumber(result) {
                this.newClientPhoneNumberInternational = result.formatInternational;
            },
            getCurrencySymbolFromString(currency) {
                return Utils.getCurrencySymbolFromString(currency)
            },
        }

    };
</script>
